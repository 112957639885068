<template>
  <div v-if="canAccess('cobranzas_admin')">
    <div class="p-grid">
      <div class=" p-col-12 card card-w-title">
        <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
        <Toolbar class="p-col-12">
          <template #left>
            <div class="p-text-right p-fluid filter p-ml-2">

              <div class="p-grid p-fluid filter p-ml-2">

                <div class="p-field p-fluid  p-mr-2">
                                    <span class="p-float-label">
                                        <InputText id="enroll" v-model="search" @keyup.enter="filter()"/>
                                        <label for="enroll">Buscar por</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid">
                                    <span class="p-float-label">
                                        <Calendar id="start_date" :showIcon="true" v-model="filters.from"
                                                  dateFormat="yy-mm-dd"
                                                  :yearNavigator="true" yearRange="2000:2050" class="p-inputtext-sm"
                                                  @date-select="filter()"/>
                                        <label for="start_date">Desde</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-ml-2">
                                    <span class="p-float-label">
                                        <Calendar id="end_date" :showIcon="true" v-model="filters.to"
                                                  dateFormat="yy-mm-dd"
                                                  :yearNavigator="true" yearRange="2000:2050" class="p-inputtext-sm"
                                                  @date-select="filter()"/>
                                        <label for="end_date">Hasta</label>
                                    </span>
                </div>

                <div class="p-field   p-fluid   p-ml-2" v-if="isAdmin">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedC" :options="countries" optionLabel="country"
                                                 @change="selectedSubsidiary=null;selectedExecutive=null;selectedM=null;selectedW=null;pRaiseMoney=[];filter()"/>
                                        <label for="country">País</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid  p-ml-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedM" :options="months" optionLabel="month_process"
                                                 @change="selectedW=null;pRaiseMoney=[];filter()"/>
                                        <label for="months">Mes</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid   p-ml-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedW" :options="weeks" optionLabel="week_process"
                                                 @change="pRaiseMoney=[];filter()"/>
                                        <label for="weeks">Semana</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid   p-ml-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedSubsidiary" :options="subsidiaries" optionLabel="name"
                                                 @change="pRaiseMoney=[];filter()"/>
                                        <label for="subisdiares">Filial</label>
                                    </span>
                </div>
                <div class="p-field   p-fluid   p-ml-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedExecutive" :options="executives" optionLabel="executive"
                                                 @change="pRaiseMoney=[];filter()"/>
                                        <label for="executives">Ejecutivos</label>
                                    </span>
                </div>

                <div class="p-field   p-fluid  p-ml-2">
                  <Button label="Limpiar" icon="pi pi-undo" class="p-button-secondary p-mr-2"
                          @click="reload"/>
                </div>
                <!-- <div class="p-field   p-fluid  p-ml-2">
                    <Button label="Filtrar" icon="pi pi-search" class="p-button-info p-mr-2"
                    @click="filter(search)"/>
                </div> -->
              </div>
            </div>
          </template>
        </Toolbar>
        <div class="p-fluid filter p-ml-2">
          <Tag class="p-mr-2" :value="'ASIGNADOS: '+ assignment " severity="info" icon="pi pi-check"></Tag>&nbsp;
          <Tag class="p-mr-2" :value="'SIN ASIGNAR: '+ noAssignment" severity="warning"
               icon="pi pi-exclamation-triangle"></Tag>
        </div>
      </div>
      <DataTable :value="pRaiseMoney" :paginator="true" dataKey="id"
                 v-model:selection="currentItem" selectionMode="single"
                 paginatorPosition="bottom"
                 :lazy="true"
                 :totalRecords="page.total"
                 :rows="page.per_page"
                 :loading="loading"
                 @page="onPage($event)">
        <Column header="No. Matrícula" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <router-link
                  :to="'/raise-money/detail/'+b64EncodeUnicode(slotProps.data.sequential)+'/'+slotProps.data.processed">
                {{ slotProps.data.sequential }}
              </router-link>
            </div>
          </template>
        </Column>
        <Column header="País" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">
              <div class="center">{{ slotProps.data.contract?.country?.name }}</div>
            </div>
          </template>
        </Column>
        <Column header="Filial" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.subsidiary?.name }}</div>
          </template>
        </Column>
        <Column header="Cédula Titular" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.contract?.holder?.dni }}</div>
          </template>
        </Column>
        <Column header="Nombre Titular" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.contract?.holder?.name }}
              {{ slotProps.data.contract?.holder?.last_name }}
            </div>
          </template>
        </Column>
        <Column header="Mes" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.month_process }}
            </div>
          </template>
        </Column>
        <Column header="Semana" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center">{{ slotProps.data.week_process ?? '-'}}</div>
          </template>
        </Column>
        <Column header="Plan" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ slotProps.data.plan?.name }}</div>
          </template>
        </Column>
        <Column header="Fecha Primer Pago" headerStyle="text-align: center;">
          <template #body="slotProps">
            <div class="center"> {{ slotProps.data.contract?.date_first_payment }}</div>
          </template>
        </Column>
        <Column header="Ejecutivo de cuenta" headerStyle="text-align: center; width:250px;">
          <template #body="slotProps">
            <div class="p-field">
              <span class="p-float-label">
                  <AutoComplete forceSelection v-on:item-select="setAssignment(slotProps.data, $event)" :dropdown="true"
                                :multiple="false" field="description"
                                v-model="slotProps.data.executive_account_name"
                                @complete="getCatalog($event, 'subsidiaries/'+slotProps.data.subsidiary_id+'/account-executives/' + slotProps.data.contract.holder_id, 'description')"
                                :suggestions="personsAssignments">
                  </AutoComplete>
              </span>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import service from '../../service/raiseMoney.service'
import catalogService from "../../../core/service/catalog.service";
import mw from '../../service/mw.service'
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";
import moment from "moment";

export default {
  mixins: [bouncer],
  name: "RaiseMoneyList",
  props: {
    enroll: Object,
    edit: Boolean,
  },
  components: {},
  data() {
    return {
      page: {
        page: 1,
        per_page: 15,
        total: 0,
        order: "desc"
      },
      assignment: 0,
      noAssignment: 0,
      pRaiseMoney: [],
      data: {},
      selectedWeek: null,
      selectedMonth: null,
      filters: {},
      submitted: false,
      loading: false,
      selectedSubsidiary: null,
      selected: false,
      selectedC: null,
      selectedM: null,
      selectedW: null,
      selectedExecutive: null,
      search: '',
      countries: [],
      personsAssignments: [],
    }
  },
  methods: {
    b64EncodeUnicode(str) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode('0x' + p1);
      }));
    },
    onPage(event) {
      this.page.page = event.page + 1;
      this.filter(this.search)
    },

    enable() {
      this.selected = true
    },
    getData(params = {}) {
      this.loading = true;
      const config = {
        ...this.page,
        ...params
      };
      mw.getMWSELiq().then(x => {
        this.countries = x.data
        if (!this.isAdmin) {
          this.selectedC = this.countries[0];
        }
      })
      service.get(null, config).then(x => {
        this.pRaiseMoney = x.data.result.data;
        this.page.total = x.data.result.total;
        this.assignment = x.data.assignments;
        this.noAssignment = x.data.no_assignments;
      }).finally(() => this.loading = false)
    },
    reload() {
      this.selectedC = null;
      this.selectedW = null;
      this.selectedM = null;
      this.selectedExecutive = null;
      this.filters = {};
      this.search = '';
      this.getData();
    },
    filter() {
      this.loading = true;

      const config = {
         q:this.search,
        page:this.page.page,
        ...{'weeks': this.selectedW ? this.selectedW.id : null},
        ...{'months': this.selectedM ? this.selectedM.id : null},
        ...{'country': this.selectedC ? this.selectedC.id : null},
        ...{'subsidiary': this.selectedSubsidiary ? this.selectedSubsidiary.id : null},
        ...{'executive': this.selectedExecutive ? this.selectedExecutive.id : null},
        ...{'to': this.filters.to ? moment(this.filters.to).format("YYYY-MM-DD") : null},
        ...{'from': this.filters.from ? moment(this.filters.from).format("YYYY-MM-DD") : null}
      };

      service.get(null, config).then(x => {
        this.pRaiseMoney = x.data.result.data;
        this.assignment = x.data.assignments;
        this.noAssignment = x.data.no_assignments;
        this.page.total = x.data.result.total;
        this.page.page = x.data.result.current_page;
        this.page.per_page=parseInt( x.data.result.per_page+'');
      }).finally(() => this.loading = false)

    },
    selectData() {
      this.$router.push('/raise-money/detail/' + this.b64EncodeUnicode(this.currentItem.sequential) + '/' + this.currentItem.processed);
    },
    getCounties() {
      catalogService.get(`select/countries/subsidiaries`).then(response => {
        this.countries = response.data;
      });
    },
    setAssignment(data, idAssignment) {
      this.loading = true
      service.assigment(data.processed, {
        'employee': idAssignment.value.id
      }).then(() => {
        data.executive_account_name = idAssignment.value.description;
        this.loading = false
        this.$toast.add({
          severity: 'success',
          summary: 'Registro guardado',
          detail: 'Se guardó correctamente',
          life: this.$utils.toastLifeTime()
        });

      }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        this.loading = false
      });
    },
    getCatalog(event, route) {
      catalogService.getCatalog(event, route, 'description').then(x => {
        this.personsAssignments = x;
      });
    },
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      return this.selectedC ? this.selectedC.subsidiaries : [];
    },
    executives() {
      return this.selectedC ? this.selectedC.executives : [];
    },
    months() {
      const result = this.selectedC ? this.selectedC.months : [];
      if (result.length === 0 && this.selectedC) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen meses asociados al país',
          life: this.$utils.toastLifeTime()
        });
      }
      return result;
    },
    weeks() {
      const result = this.selectedM ? this.selectedM.weeks : [];
      if (result.length === 0 && this.selectedM) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Atención',
          detail: 'No existen semanas asociadas al mes',
          life: this.$utils.toastLifeTime()
        });
      }
      return result;
    }
  },
  mounted() {
    if (this.canAccess('cobranzas_admin')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Lista de Matriculas para gestión de Cobranza', url: "javascript:void(0);"},
      ]);
      this.getData();
    }
  }
}
</script>

<style scoped lang="scss">

.p-fieldset {
  margin-top: 15px;
}

.p-field {
  padding-top: 15px;
}

.p-dialog-footer {
  padding: 0;
  margin-bottom: -15px;
}

.p-grid {
  margin-top: 15px;
}

.p-toolbar {
  background: none;
  border: none;
}

.p-inputtext {
  width: 100%;
}

.p-autocomplete {
  height: 30px !important;
}

.df, .df #status, .p-fluid .p-dropdown {
  height: 100%;
}

.p-selectable-row td:first-child, .uc {
  padding-left: 1.5rem !important;
  display: inline-block;
}

.center, .p-dialog .p-dialog-footer {
  text-align: center;
}

.p-message-wrapper > span {
  display: none !important;
}

</style>
